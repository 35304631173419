import fetch from '../../api/fetch';
import $ from '../../util/fancybox-jquery';

export default class QuotationForm {
    public static bindQuotationForm(): void {
        $('#businessQuotationForm').on('submit', (event) => {
            QuotationForm.handleBusinessQuotationForm();
            event.preventDefault();
        });
    }

    private static handleBusinessQuotationForm(): void {
        const businessQuotationForm = $('#businessQuotationForm');
        const formData = new FormData(businessQuotationForm[0] as HTMLFormElement);

        const json = Array.from(formData).reduce<Record<string, FormDataEntryValue>>(
            (carry, [key, value]) => ({
                ...carry,
                [key]: value,
            }),
            {},
        );

        fetch('business/quotation', {
            body: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })
            .then((response) => response.json())
            .then((data: { errors: string[]; success: boolean }) => {
                businessQuotationForm.find('input').removeClass('error');
                businessQuotationForm.find('.error_emailAddress').addClass('hidden');
                businessQuotationForm.find('.error_msg').addClass('hidden');

                if (data.success) {
                    $('.businessQuotation__success').removeClass('hidden');
                    businessQuotationForm.find('input').addClass('success');
                    businessQuotationForm.find('button').addClass('hidden').attr('disabled', 'disabled');
                    return;
                }

                data.errors.forEach((value) => {
                    businessQuotationForm.find(`input[name=${value}]`).addClass('error');
                    businessQuotationForm.find(`.error_${value}`).removeClass('hidden');
                    if (value === 'emailAddress') {
                        businessQuotationForm.find('.error_emailAddress').removeClass('hidden');
                        businessQuotationForm.find('.validEmail').addClass('error');
                    }
                });
            }).catch(() => null);
    }
}
